<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
        <div class="card card-body">
            <div class="row">
                <div class="col-12 col-lg-6 g">
                    <button class="btn btn-block btn-success" @click="between = 1; reports = []">
                        <i class="fa fa-calendar-o"></i>
                        تقرير يوم
                    </button>
                </div>
                <div class="col-12 col-lg-6 g">
                    <button class="btn btn-block btn-primary" @click="between = 2; reports = []">
                        <i class="fa fa-calendar"></i>
                        تقرير فترة
                    </button>
                </div>
            </div>
            <hr v-if="between != 0">
            <div v-if="between != 0">
                <div class="form-group" v-if="between == 1">
                <h5 for="">التاريخ</h5>
                <input type="date"
                    class="form-control form-control-lg" v-model="date">
                </div>
                <div class="form-group" v-if="between == 2">
                <h5 for="">الفترة من</h5>
                <input type="date"
                    class="form-control form-control-lg" v-model="from">
                </div>
                <div class="form-group" v-if="between == 2">
                <h5 for="">الفترة الى</h5>
                <input type="date"
                    class="form-control form-control-lg" v-model="to">
                </div>
                <span>اختر الفصل</span>
                <select class="form-control" v-model="classroom">
                    <option value="">-- اختر --</option>
                    <option v-for="c in classes" :key="c._id" :value="c.classname + ',' + c.classroom">
                        {{ c.classname }} - {{ c.classroom }}
                    </option>
                </select>
                <div class="col-12 text-center g">
                    <br>
                    <button class="btn btn-primary" @click="search()">
                        عرض التقرير
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 c g" v-if="between != 0 && reports.length">
        <div class="card card-body">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-bordered table-striped" v-if="between == 1">
                    <thead>
                        <th>
                            الاسم
                        </th>
                        <th>
                            رقم الهوية
                        </th>
                        <th>
                            الحضور
                        </th>
                        <th>
                            الغياب
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="report in reports" :key="report._id">
                            <td>
                                {{ report.name }}
                            </td>
                            <td>
                                {{ report.number }}
                            </td>
                            <td>
                                <span v-for="l in report.attend" :key="l" class="btn btn-sm btn-success" style="border-radius: 0px; padding: 5px; border: none !important;">
                                    {{ l }}
                                </span>
                            </td>
                            <td>
                                <span v-for="l in report.ghiab" :key="l" class="btn btn-sm btn-danger" style="border-radius: 0px; padding: 5px; border: none !important;">
                                    {{ l }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-hover table-sm table-bordered table-striped" v-if="between == 2">
                    <thead>
                        <th>
                            الاسم
                        </th>
                        <th>
                            رقم الهوية
                        </th>
                        <th>
                            اجمالي الحضور
                        </th>
                        <th>
                            اجمالي الغياب
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="report in reports" :key="report._id">
                            <td>
                                {{ report.name }}
                            </td>
                            <td>
                                {{ report.number }}
                            </td>
                            <td>
                                {{ report.attend }}
                            </td>
                            <td>
                                {{ report.ghiab }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BFormCheckbox,
    },
    directives: {
        Ripple,
    },
    data(){
        return {
            teacher: JSON.parse(localStorage.getItem('teacher')),
            reports: [],
            date: new Date().toISOString().split("T")[0],
            classes: [],
            classrooms: [],
            classroom: "",
            q: "",
            between: 0,
            from: new Date().toISOString().split("T")[0],
            to: new Date().toISOString().split("T")[0],
        }
    },
    created(){
        var g = this;
        $.post(api + '/teacher/classes', {
            username: this.teacher.username,
            password: this.teacher.password
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.classes = r.response;
            }
        }).fail(function(){
        })
    },
    methods: {
        search(){
            var g = this;
            $.post(api + '/teacher/report' + (g.between  == 2 ? '-between' : ''), {
                username: this.teacher.username,
                password: this.teacher.password,
                classroom: this.classroom,
                date: this.date,
                from: this.from,
                to: this.to
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    g.reports = r.response;
                }
            }).fail(function(){
            })
        }
    }
}
</script>